<template>
  <article>
    <div class="card__header">
      <div class="card__campaign">
        <img :src="thumbnail || defaultImage" :alt="campaignName" />
        <h3 :title="campaignName" :class="{ 'in-modal': modal }">
          {{ campaignName }}
        </h3>
      </div>
      <div
        v-if="campaignCount || campaignCount == 0"
        class="card__totalTalents"
      >
        <span>Total</span>
        <b>{{ campaignCount }}</b>
      </div>
      <button
        class="card__toggler btn btn-secondary btn-sm"
        @click.stop="toggleCard"
      >
        <img
          v-if="!expand && !isLoading"
          src="@/assets/images/icons/arrow-down.svg"
          alt="arrow icon"
        />
        <div v-else-if="expand && isLoading">
          <v-progress-circular
            :size="15"
            :width="2"
            color="info"
            indeterminate
          ></v-progress-circular>
        </div>
        <img
          v-else-if="expand && !isLoading"
          src="@/assets/images/icons/arrow-up.svg"
          alt="arrow icon"
        />
      </button>
    </div>
    <transition name="card">
      <div v-if="expand" class="card__content">
        <template v-if="campaignDetails && talents">
          <div
            class="card__statistics"
            v-b-tooltip.hover.top="`Has active contract`"
          >
            <b>{{ campaignDetails.hired }}</b>
            <span v-if="campaignCount != 0">
              Hired
              {{ ((campaignDetails.hired / campaignCount) * 100).toFixed(2) }}%
            </span>
            <span v-else> Hired 0% </span>
          </div>
          <div class="card__statistics" v-b-tooltip.hover.top="`Registered`">
            <b>{{ campaignDetails.applicant }}</b>
            <span v-if="campaignCount != 0">
              Applicant
              {{
                ((campaignDetails.applicant / campaignCount) * 100).toFixed(2)
              }}%
            </span>
            <span v-else> Applicant 0% </span>
          </div>
        </template>
        <template v-else-if="campaignDetails && !talents">
          <div class="card__statistics small">
            <b>{{ campaignDetails.uncompleted }}</b>
            <span>Uncompleted Lead</span>
          </div>
          <div class="card__statistics small">
            <b>{{ campaignDetails.completed }}</b>
            <span>Completed Lead</span>
          </div>
          <div class="card__statistics small">
            <b>{{ campaignDetails.active }}</b>
            <span>Client (Active Squads)</span>
          </div>
        </template>
      </div>
    </transition>
  </article>
</template>

<script>
import axios from "axios";

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    thumbnail: {
      type: String,
      default: null,
    },
    campaignId: {
      type: Number,
    },
    campaignName: {
      type: String,
      default: "Campaign",
    },
    campaignCount: {
      type: Number,
      default: 0,
    },
    date: {
      type: Object,
      default: () => {},
    },
    talents: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expand: false,
      isLoading: false,
      apiCalled: false,
      campaignDetails: null,
    };
  },
  watch: {
    expand(value) {
      if (value && !this.apiCalled) this.getCampaignDetails();
      if (value) this.$emit("card-opened");
    },
    open(value) {
      this.expand = value;
    },
  },
  computed: {
    defaultImage() {
      return require(`../../assets/images/placeholder-min.jpg`);
    },
  },
  methods: {
    toggleCard() {
      this.expand = !this.expand;
    },
    async getCampaignDetails() {
      let timeRange = "";
      this.isLoading = true;

      if (this.date && this.date.start && this.date.end)
        timeRange = `?start_date=${this.date.start}&end_date=${this.date.end}`;
      await axios
        .get(
          `/home-stats/${
            this.talents ? "talents" : "clients"
          }/campaigns/details/${this.campaignId}${timeRange}`
        )
        .then((res) => {
          this.campaignDetails = res.data;
          this.apiCalled = true;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  async mounted() {
    this.expand = this.open;
    this.expand && (await this.getCampaignDetails());
  },
};
</script>

<style lang="scss" scoped>
.card {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img {
      border-radius: 5px;
      width: 35px;
      height: 35px;
    }
  }

  &__toggler {
    background-color: #eee;

    img {
      width: 15px;
      height: 9px;
    }
    &::after {
      margin-left: 0 !important;
    }
  }

  &__campaign {
    display: flex;
    align-items: center;

    h3 {
      margin-inline-start: 10px;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      max-width: 135px;

      &.in-modal {
        @media (min-width: 786px) {
          max-width: 200px;
        }
      }
    }
  }

  &__totalTalents {
    margin-inline-start: auto;
    margin-inline-end: 20px;

    span {
      margin-inline-end: 10px;
    }

    b {
      font-size: 16px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fafafa;
    border-radius: 19px;
    overflow: hidden;
    opacity: 1;
    height: 85px;
  }

  &__statistics {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;

    &:not(:last-child) {
      border-inline-end: 1px solid #edebeb;
    }

    b {
      color: #000;
      font-size: 19px;
    }

    span {
      font-size: 14px;
      color: rgba(102, 102, 102, 1);
    }

    &.small {
      span {
        font-size: 12px;
      }
    }
  }
}

.card-enter-active,
.card-leave-active {
  transition: all 0.5s ease-out;
}

.card-enter {
  height: 0;
  opacity: 0;
  transform: translateY(-50%);
}

.card-leave-to {
  height: 0;
  opacity: 0;
  transform: translateY(-50%);
}
.rotate {
  display: block;
  transform: rotateZ(180deg);
}
</style>
