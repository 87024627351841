<template>
  <article>
    <div class="card__header">
      <div class="card__profile">
        <img :src="currentAccountImage()" :alt="name" />
        <h3 :title="name" :class="{ 'in-modal': modal }">{{ name }}</h3>
      </div>
      <div class="card__numOfSquads">
        <span>No of Squads</span>
        <b>{{ noOfSquads }}</b>
      </div>
      <button
        class="card__toggler btn btn-secondary btn-sm"
        @click.stop="toggleCard"
      >
        <img
          v-if="!expand && !isLoading"
          src="@/assets/images/icons/arrow-down.svg"
          alt="arrow icon"
        />
        <div v-else-if="expand && isLoading">
          <v-progress-circular
            :size="15"
            :width="2"
            color="info"
            indeterminate
          ></v-progress-circular>
        </div>
        <img
          v-else-if="expand && !isLoading"
          src="@/assets/images/icons/arrow-up.svg"
          alt="arrow icon"
        />
      </button>
    </div>
    <transition name="card">
      <div
        v-if="expand && accountDetails && accountDetails.length"
        class="card__content"
        :style="`max-height: ${90 * accountDetails.length}px`"
      >
        <div
          class="card__projects"
          v-for="project in accountDetails"
          :key="project.id"
        >
          <figure>
            <img
              :src="project.poster || defaultImage(project.name)"
              :alt="project.name"
            />
          </figure>
          <b>{{ project.name || "No Name" }}</b>
          <a
            :href="`/dashboard/client/${project.client_id}/project/${project.id}`"
          >
            View
          </a>
        </div>
      </div>
    </transition>
  </article>
</template>

<script>
import axios from "axios";

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    profileImage: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      default: null,
    },
    id: {
      type: Number,
      default: 0,
    },
    noOfSquads: {
      type: Number,
      default: 0,
    },
    date: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: "User",
    },
  },
  data() {
    return {
      expand: false,
      isLoading: false,
      apiCalled: false,
      accountDetails: null,
      dashboardAccounts: [
        {
          name: "ahmed-emam",
          email: "ahmed.emam@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/H56aJ4OHLhqaYaDOesDqkvHVW3nYHLXugb54zsZB.jpeg",
        },
        {
          name: "cyrine-arous",
          email: "cyrin.arous@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/S5votsX7K7OBCRqVU04nnmSSKNUkEu5Gn0a3PnMp.jpeg",
        },
        {
          name: "esraa-galal",
          email: "esraa.galal@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/uaBP34TiI53jY6FqLkfaTHW2KHrmGiZHG280wbt6.jpeg",
        },
        {
          name: "hajar-sayed",
          email: "hajar.sayed@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/vc3A9IwLInsU1s0pw3AZdTkAw0DtqcW0UZ760Hkj.jpeg",
        },
        {
          name: "hossam-malek",
          email: "hossam.malek@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/kVz1jFwDTWw4uaLWPyj1Sz1WMbXlI9m7v2gIJKVd.jpeg",
        },
        {
          name: "lujain-alsharif",
          email: "lujain.alsharif@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/fHJlk1ER7pHIXkFPJibzvFGqqtyhBafNO1zKpaF3.jpeg",
        },
        {
          name: "maisoun-elgharib",
          email: "maisoun.elgharib@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/UTgZFgvTGZ1IT7wfkcxr88SYoLF2OPE6nW5f6NRG.jpeg",
        },
        {
          name: "menna-galal",
          email: "menna.el-negoumy@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/wk92i7G4wAcjGCqAYQ3emavCJPnOhOduYpbyQLx7.jpeg",
        },
        {
          name: "mohamed-arafa",
          email: "mohamed.arafa@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/JTMZQ8F6wQaF8YIrIfy0VvE4MVcaK4Uep8PhqNLM.jpeg",
        },
        {
          name: "nayera-elshamy",
          email: "nayera.elshamy@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/X3bsDegpo1BD4ungZ8C4glSI4toJKt0lUg3gVXgK.jpeg",
        },
        {
          name: "omnia-sami",
          email: "omnia.mahmoud@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/UH3wAowEFHsL7d3g1h0yHf5i22A2iHHbuEMSdPio.jpeg",
        },
        {
          name: "rania-ossama",
          email: "rania.ossama@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/8lZzzTHOPsXpPLxf38lOtHIDDKVShfwMztqMwDtV.jpeg",
        },
        {
          name: "reham-yusuf",
          email: "reham.yusuf@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/4zYnyPTqeBlWc725orAKhFMxPn42bPUCMAMP0YFY.jpeg",
        },
        {
          name: "salma-tarek",
          email: "salma.tarek@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/SPzsMKsno0Uf841lCGN2l2QOf6tjAnlWH2ZOn8D7.jpeg",
        },
        {
          name: "shaimaa-maged",
          email: "shaimaa.maged@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/GZHS1y8PWGdHcFQREnWUE62tQULgdeyi12MCdDuB.jpeg",
        },
        {
          name: "waleed-tayel",
          email: "waleed.tayel@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/DWvgmEWLfNic0PuGg3pGoJrSd1b93A8JNn6jRk5q.jpeg",
        },
        {
          name: "mennatallah-galal",
          email: "mennatallah.galal@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/dJdhNNTKPswt0Oo0pKXcs0uSSHDEvapivQrpEB1k.png",
        },
        {
          name: "mohammed-alyami",
          email: "mohammed.alyami@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/KXTZkvMMg8OD3Augktaao5QOrIWLrWwrGsFs89tW.png",
        },
        {
          name: "senawy",
          email: "senawy@squadio.com",
          image:
            "https://squadio-uploads-production.s3.eu-central-1.amazonaws.com/image/user/rb6Rgx0ra63NNxjeIlrEgMxSCNuu9gcbOQh5fUiQ.png",
        },
      ],
    };
  },
  watch: {
    expand(value) {
      if (value && !this.apiCalled) this.getAccountDetails();
      if (value) this.$emit("card-opened");
    },
    open(value) {
      this.expand = value;
    },
  },
  methods: {
    defaultImage(name) {
      if (!name)
        return `https://placehold.co/60x40/ecf0f1/3498db?font=lato&text=NA`;
      const words = name.split(" ");
      let initials = "";

      for (let i = 0; i < Math.min(2, words.length); i++) {
        const word = words[i];
        initials += word[0];
      }

      return `https://placehold.co/60x40/ecf0f1/3498db/?font=lato&text=${initials.toLocaleUpperCase()}`;
    },
    currentAccountImage() {
      const account = this.dashboardAccounts.find(
        (account) => account.email == this.email
      );

      if (account && account.name) return account.image;
      else return this.profileImage;
    },
    toggleCard() {
      this.expand = !this.expand;
    },
    async getAccountDetails() {
      let timeRange = "";
      this.isLoading = true;

      if (this.date && this.date.start && this.date.end)
        timeRange = `&start_date=${this.date.start}&end_date=${this.date.end}`;
      await axios
        .get(
          `/home-stats/clients/accounts/details/${this.id}?type=${this.type}${timeRange}`
        )
        .then((res) => {
          this.accountDetails = res.data;
          this.apiCalled = true;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  async mounted() {
    this.expand = this.open;
    this.expand && (await this.getAccountDetails());
  },
};
</script>

<style lang="scss" scoped>
.card {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img {
      border-radius: 5px;
      width: 45px;
      height: 45px;
    }
  }

  &__toggler {
    background-color: #eee;

    img {
      width: 15px;
      height: 9px;
    }
    &::after {
      margin-left: 0 !important;
    }
  }

  &__profile {
    display: flex;
    align-items: center;

    h3 {
      margin-inline-start: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100px;

      &.in-modal {
        @media (min-width: 786px) {
          max-width: 200px;
        }
      }
    }

    img {
      object-fit: contain;
      border-radius: 50%;
    }
  }

  &__numOfSquads {
    margin-inline-start: auto;
    margin-inline-end: 10px;

    span {
      margin-inline-end: 10px;
      white-space: nowrap;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #fafafa;
    border-radius: 19px;
    overflow-x: hidden;
    overflow-y: scroll;
    opacity: 1;
    height: 200px;
    padding: 10px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__projects {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 7px;
    padding: 7px 0;
    gap: 7px;

    &:not(:last-child) {
      border-bottom: 1px solid #edebeb;
    }

    figure {
      margin: 0 !important;
    }

    b {
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
    }

    a {
      margin-left: auto;
      color: #1b1b1b;
      text-decoration: underline;
    }

    figure {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      min-width: 60px;
      height: 40px;
      margin-right: 7px;
      background-color: #fff;
      border-radius: 5px;
      overflow: hidden;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.card-enter-active,
.card-leave-active {
  transition: all 0.5s ease-out;
}

.card-enter {
  height: 0;
  opacity: 0;
  transform: translateY(-50%);
}

.card-leave-to {
  height: 0;
  opacity: 0;
  transform: translateY(-50%);
}

.rotate {
  display: block;
  transform: rotateZ(180deg);
}
</style>
