<template>
  <div class="profile-strength-donut">
    <apexchart
      :width="width"
      :height="height"
      :options="options"
      :series="data"
      type="donut"
    >
    </apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Chart",
  props: {
    title: {
      type: String,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
    data: {
      type: Array,
    },
    colors: {
      type: Array,
    },
    labels: {
      type: Array,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    options() {
      return {
        legend: {
          position: "bottom",
        },
        dataLabels: {
          enabled: false,
          position: "top",
        },
        chart: {
          type: "donut",
        },
        colors: this.colors,
        noData: {
          text: this.loading ? "Loading..." : "No Data",
        },
        labels: this.labels,
      };
    },
  },
};
</script>

<style>
.profile-strength-donut .apexcharts-pie-label {
  font-weight: 700;
  font-size: 0.925rem;
}

.profile-strength-donut foreignObject {
  display: flex;
}

.profile-strength-donut .apexcharts-inner.apexcharts-graphical {
  width: 100%;
  display: flex;
}

.profile-strength-donut .apexcharts-legend {
  flex-direction: column;
  display: none;
}
</style>
