<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-12">
        <div class="dashboard-cta-wrapper">
          <ul class="dashboard-switcher">
            <li>
              <a
                @click.prevent="activeTab = 'clients'"
                href="#"
                :class="activeTab == 'clients' ? 'active' : ''"
                >Clients</a
              >
            </li>
            <li>
              <a
                @click.prevent="activeTab = 'talents'"
                href="#"
                :class="activeTab == 'talents' ? 'active' : ''"
                >Talents</a
              >
            </li>
          </ul>
          <div class="dashboard-filter">
            <div data-app class="v-application">
              <v-dialog
                ref="startDataDialog"
                v-model="menu"
                :return-value.sync="dates"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Select Date Range"
                    placeholder="Select Date Range"
                    v-model="dates"
                    readonly
                    clearable
                    solo
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="resetDates"
                  >
                    <template
                      v-slot:append
                      class="dashboard-filter__calendar-icon"
                    >
                      <v-icon class="mr-2"> mdi-calendar </v-icon>
                    </template>
                  </v-text-field>
                </template>

                <v-date-picker
                  v-model="dates"
                  range
                  :max="new Date().toISOString().slice(0, 10)"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    v-if="datesApplied"
                    text
                    color="primary"
                    @click="resetDates"
                  >
                    Reset
                  </v-btn>
                  <v-btn text color="primary" @click="setStartAndEndDates">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="activeTab == 'talents'" class="row mt-10">
      <div class="col-xl-12 dashboard-card mb-10 talent-stats">
        <h2 class="title-text mb-8">
          <span>Total Number Of Talents </span>
          <small v-if="talentsStats" class="sum-wrapper">
            {{ talentsStats.total }}
          </small>
        </h2>
        <h3 class="subtitle mb-1">
          <span>Number of new signups </span>
          <small v-if="talentsStats" class="sum-wrapper">
            {{ talentsStats.new_signups }}
          </small>
        </h3>
        <div v-if="talentsStats" class="talents-stats__wrapper">
          <div class="main-talents-statistics-wrapper row">
            <div
              v-if="
                !talentsStats ||
                (!Number(talentsStats.not_qualified.missing) &&
                  !Number(talentsStats.qualified) &&
                  !Number(talentsStats.not_qualified.failed) &&
                  !Number(talentsStats.not_qualified.pending) &&
                  !isLoadingTalentsStats &&
                  !loadingTalentsStatsError)
              "
              class="text-center text-xl"
            >
              <p class="mt-10 font-weight-bolder">
                We don't have available data for the current applied filters
              </p>
            </div>
            <template
              v-else-if="!isLoadingTalentsStats && !loadingTalentsStatsError"
            >
              <ul class="registration-talents col-lg-9 row">
                <li class="col-lg-3">
                  <span
                    class="registration-talents__key"
                    :style="`--bullet-color: ${
                      chartsColors[3 % chartsColors.length]
                    }`"
                  >
                    Qualified
                  </span>
                  <b class="registration-talents__value">
                    {{ talentsStats.qualified }}
                  </b>
                </li>
                <li class="col-lg-3">
                  <span
                    class="registration-talents__key"
                    :style="`--bullet-color: ${
                      chartsColors[2 % chartsColors.length]
                    }`"
                    >Disqualified
                  </span>
                  <b class="registration-talents__value">
                    {{ talentsStats.not_qualified.failed }}
                  </b>
                </li>
                <li class="col-lg-3">
                  <span
                    class="registration-talents__key"
                    :style="`--bullet-color: ${
                      chartsColors[1 % chartsColors.length]
                    }`"
                    v-b-tooltip.hover.top="
                      `Number of talents with no assessments on main role`
                    "
                    >With no assess.
                  </span>
                  <b class="registration-talents__value">
                    {{ talentsStats.not_qualified.missing }}
                  </b>
                </li>
                <li class="col-lg-3">
                  <span
                    class="registration-talents__key"
                    :style="`--bullet-color: ${
                      chartsColors[0 % chartsColors.length]
                    }`"
                    v-b-tooltip.hover.top="
                      `Number of talents having assessments and not taken yet`
                    "
                  >
                    Pending assessment
                  </span>
                  <b class="registration-talents__value">
                    {{ talentsStats.not_qualified.pending }}
                  </b>
                </li>
              </ul>
              <div class="signup_chart_wrapper col-lg-3">
                <DoughnutRegistrationTalents
                  class="foo-chart"
                  :width="240"
                  :height="240"
                  :loading="false"
                  :data="[
                    talentsStats.qualified,
                    talentsStats.not_qualified.failed,
                    talentsStats.not_qualified.missing,
                    talentsStats.not_qualified.pending,
                  ]"
                  :labels="[
                    'Qualified',
                    'Disqualified',
                    'With no assessments',
                    'Pending assessment',
                  ]"
                  :colors="[
                    chartsColors[3 % chartsColors.length],
                    chartsColors[2 % chartsColors.length],
                    chartsColors[1 % chartsColors.length],
                    chartsColors[0 % chartsColors.length],
                  ]"
                />
              </div>
            </template>
            <div v-else-if="loadingTalentsStatsError" class="error-state">
              <div class="text-center mt-10">
                <p class="font-weight-bolder text-danger">
                  Something went wrong, please try again
                </p>
                <v-btn
                  :loading="isLoadingTalentsStats"
                  class="flex-grow-1 indigo-darken-3"
                  height="48"
                  variant="flat"
                  @click="getTalentsStats"
                >
                  Try again
                </v-btn>
              </div>
            </div>
            <div
              v-else-if="isLoadingTalentsStats && !loadingTalentsStatsError"
              class="loading-state"
            >
              <v-progress-circular
                :size="50"
                :width="4"
                color="info"
                indeterminate
                class="mr-3"
              ></v-progress-circular>
            </div>
          </div>
          <div class="availability_chart_wrapper">
            <h2 class="title-text col-lg-12 p-0">
              <span>Hired Talents </span>
              <small v-if="talentsStats" class="sum-wrapper">
                {{ Number(talentsStats.hired.total) }}
              </small>
            </h2>
            <div
              v-if="
                !talentsStats.hired ||
                (!Number(talentsStats.hired.full_time) &&
                  !Number(talentsStats.hired.part_time) &&
                  !Number(talentsStats.hired.monthly_hours) &&
                  !isLoadingTalentsStats &&
                  !loadingTalentsStatsError)
              "
              class="text-center text-xl"
            >
              <p class="mt-10 font-weight-bolder">
                We don't have available data for the current applied filters
              </p>
            </div>
            <div
              v-else-if="!isLoadingTalentsStats && !loadingTalentsStatsError"
              class="d-flex mt-10"
            >
              <div class="availability_chart">
                <div
                  class="availability_chart__bar"
                  :style="`
                    --height: ${(
                      (talentsStats.hired.full_time /
                        (talentsStats.hired.full_time +
                          talentsStats.hired.part_time +
                          talentsStats.hired.monthly_hours)) *
                      100
                    ).toFixed(2)}%;
                    --main-color: #ffc629;
                    --secondary-color: #ffd86e;`"
                ></div>

                <div
                  class="availability_chart__bar"
                  :style="`
                    --height: ${(
                      (talentsStats.hired.part_time /
                        (talentsStats.hired.full_time +
                          talentsStats.hired.part_time +
                          talentsStats.hired.monthly_hours)) *
                      100
                    ).toFixed(2)}%;
                    --main-color: #422e87;
                    --secondary-color: #4e3997;`"
                ></div>
                <div
                  class="availability_chart__bar"
                  :style="`
                    --height: ${(
                      (talentsStats.hired.monthly_hours /
                        (talentsStats.hired.full_time +
                          talentsStats.hired.part_time +
                          talentsStats.hired.monthly_hours)) *
                      100
                    ).toFixed(2)}%;
                    --main-color: #4eee87;
                    --secondary-color: #4e3aa7;`"
                ></div>
              </div>
              <div class="availability">
                <ul style="align-items: flex-start">
                  <li>
                    <span
                      class="availability__value"
                      style="--bullet-color: #fac328"
                    >
                      {{ talentsStats.hired.full_time }}
                    </span>
                    <span
                      class="availability__key"
                      v-b-tooltip.hover.top="
                        `This is the number of Full-time contracts`
                      "
                    >
                      Full time
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                          />
                          <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
                          />
                        </svg>
                      </span>
                    </span>
                  </li>
                  <li>
                    <span
                      class="availability__value"
                      style="--bullet-color: #422e87"
                    >
                      {{ talentsStats.hired.part_time }}
                    </span>
                    <span
                      class="availability__key"
                      v-b-tooltip.hover.top="
                        `This is the number of Part-time contracts`
                      "
                    >
                      Part time
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                          />
                          <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
                          />
                        </svg>
                      </span>
                    </span>
                  </li>
                  <li>
                    <span
                      class="availability__value"
                      style="--bullet-color: #4eee87"
                    >
                      {{ talentsStats.hired.monthly_hours }}
                    </span>
                    <span
                      class="availability__key"
                      v-b-tooltip.hover.top="
                        `This is the number of Monthly hours contracts`
                      "
                    >
                      Monthly hours
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                          />
                          <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
                          />
                        </svg>
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div v-else-if="loadingTalentsStatsError" class="error-state">
              <div class="text-center mt-10">
                <p class="font-weight-bolder text-danger">
                  Something went wrong, please try again
                </p>
                <v-btn
                  :loading="isLoadingTalentsStats"
                  class="flex-grow-1 indigo-darken-3"
                  height="48"
                  variant="flat"
                  @click="getTalentsStats"
                >
                  Try again
                </v-btn>
              </div>
            </div>
            <div
              v-else-if="isLoadingTalentsStats && !loadingTalentsStatsError"
              class="loading-state"
            >
              <v-progress-circular
                :size="50"
                :width="4"
                color="info"
                indeterminate
                class="mr-3"
              ></v-progress-circular>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="dashboard-card">
          <h3 class="title-text">Campaign talents</h3>
          <div
            v-if="
              !talentsCampaigns.length &&
              !isLoadingCampaigns &&
              !loadingCampaignsError
            "
            class="text-center text-xl"
          >
            <p class="mt-10 font-weight-bolder">
              We don't have available data for the current applied filters
            </p>
          </div>
          <template v-else-if="!isLoadingCampaigns && !loadingCampaignsError">
            <small>You have {{ talentsCampaigns.length }} Campaigns</small>
            <ExpandedCard
              v-for="campaign in talentsCampaigns"
              :key="campaign.id"
              class="my-5"
              :campaign-id="campaign.id"
              talents
              :date="{ start: startDate, end: endDate }"
              :campaign-name="campaign.name"
              :campaign-count="campaign.count"
              :thumbnail="campaign.thumbnail"
              :open="activeTalentCampaignCard == campaign.id"
              @card-opened="activeTalentCampaignCard = campaign.id"
            />
          </template>
          <div v-else-if="loadingCampaignsError">
            <div class="text-center mt-10">
              <p class="font-weight-bolder text-danger">
                Something went wrong, please try again
              </p>
              <v-btn
                :loading="isLoadingCampaigns"
                class="flex-grow-1 indigo-darken-3"
                height="48"
                variant="flat"
                @click="getTalentsCampaigns"
              >
                Try again
              </v-btn>
            </div>
          </div>
          <div
            v-else-if="isLoadingCampaigns && !loadingCampaignsError"
            class="loading-state"
          >
            <v-progress-circular
              :size="50"
              :width="4"
              color="info"
              indeterminate
              class="mr-3"
            ></v-progress-circular>
          </div>
          <div class="max-height-overlay">
            <span @click="showTalentsCampaignsModal = true"> View all </span>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="dashboard-card">
          <div class="dashboard-title-text">
            Countries List
            <small
              v-if="countries.length && !isLoadingCountries"
              class="sum-wrapper"
            >
              {{ countries.length }}
            </small>
          </div>
          <div
            v-if="
              !countries.length && !isLoadingCountries && !loadingCountriesError
            "
            class="text-center text-xl"
          >
            <p class="mt-10 font-weight-bolder">
              We don't have available data for the current applied filters
            </p>
          </div>

          <template v-else-if="!isLoadingCountries && !loadingCountriesError">
            <div class="progress_bar-wrapper d-flex w-100 mb-8">
              <div
                v-for="country in countries"
                :key="country.id"
                class="progress_bar"
                :style="`--width: ${
                  (country.talents_count / countriesCount) * 100
                }%; --color: ${country.color}`"
                v-b-tooltip.hover.top="
                  `${country.name} ${country.talents_count}`
                "
              ></div>
            </div>
            <div class="d-flex">
              <div class="row">
                <div
                  class="col-lg-12"
                  v-for="country in countries"
                  :key="country.id"
                >
                  <div class="countries-card">
                    <span
                      class="countries-card__color"
                      :style="`--color: ${country.color}`"
                    ></span>
                    <span class="countries-card__name">
                      {{ country.name }}
                    </span>
                    <span class="countries-card__number">
                      {{ country.talents_count }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div v-else-if="loadingCountriesError">
            <div class="text-center">
              <p class="font-weight-bolder text-danger">
                Something went wrong, please try again
              </p>
              <v-btn
                :loading="isLoadingCountries"
                class="flex-grow-1 indigo-darken-3"
                height="48"
                variant="flat"
                @click="getTalentsCountries"
              >
                Try again
              </v-btn>
            </div>
          </div>
          <div
            v-else-if="isLoadingCountries && !loadingCountriesError"
            class="loading-state"
          >
            <v-progress-circular
              :size="50"
              :width="4"
              color="info"
              indeterminate
              class="mr-3"
            ></v-progress-circular>
          </div>
          <div class="max-height-overlay">
            <span @click="showCountriesModal = true"> View all </span>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="row mt-1">
          <div class="col-lg-12">
            <div class="dashboard-card">
              <div class="dashboard-title-text">Profile Strength</div>
              <div>
                <ul
                  class="dashboard-switcher dashboard-switcher-profile-strength"
                >
                  <li>
                    <a
                      @click.prevent="activeTabProfileStrength = 'workable'"
                      href="#"
                      :class="
                        activeTabProfileStrength == 'workable' ? 'active' : ''
                      "
                      >Workable</a
                    >
                  </li>
                  <li>
                    <a
                      @click.prevent="activeTabProfileStrength = 'signups'"
                      href="#"
                      :class="
                        activeTabProfileStrength == 'signups' ? 'active' : ''
                      "
                      >Sign up</a
                    >
                  </li>
                  <!-- <li>
                    <a
                      @click.prevent="activeTabProfileStrength = 'velents'"
                      href="#"
                      :class="
                        activeTabProfileStrength == 'velents' ? 'active' : ''
                      "
                      >Velents</a
                    >
                  </li> -->
                </ul>
              </div>

              <div
                v-if="
                  !strengths.length &&
                  !isLoadingStrengths &&
                  !loadingStrengthsError
                "
                class="text-center text-xl"
              >
                <p class="mt-10 font-weight-bolder">
                  We don't have available data for the current applied filters
                </p>
              </div>

              <div v-else-if="!loadingStrengthsError && !isLoadingStrengths">
                <div class="d-flex justify-content-center mt-6">
                  <DoughnutProfileStrength
                    v-if="strengths.length > 1"
                    :data="getStrengthsValues"
                    :colors="chartsColors"
                    :labels="getStrengthsPercentages"
                    :width="300"
                    :height="300"
                  />
                </div>
                <div class="d-flex">
                  <ul class="profile-strength-list">
                    <li v-for="strength in strengths" :key="strength.color">
                      <span class="profile-strength-list__name">
                        <span
                          class="profile-strength-list__color"
                          :style="{ backgroundColor: strength.color }"
                        ></span>
                        <span class="profile-strength-list__text">
                          Strength ({{ strength.key }}%)</span
                        >
                      </span>
                      <span class="profile-strength-list__number">
                        {{ strength.value }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div v-else-if="loadingStrengthsError">
                <div class="text-center">
                  <p class="font-weight-bolder text-danger">
                    Something went wrong, please try again
                  </p>
                  <v-btn
                    :loading="isLoadingStrengths"
                    class="flex-grow-1 indigo-darken-3"
                    height="48"
                    variant="flat"
                    @click="getTalentsProfileStrengths"
                  >
                    Try again
                  </v-btn>
                </div>
              </div>
              <div
                v-else-if="isLoadingStrengths && !loadingStrengthsError"
                class="loading-state"
              >
                <v-progress-circular
                  :size="50"
                  :width="4"
                  color="info"
                  indeterminate
                  class="mr-3"
                ></v-progress-circular>
              </div>
              <div v-if="strengths.length > 1" class="max-height-overlay">
                <span @click="showProfileStrengthsModal = true">
                  View all
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="activeTab == 'clients'" class="row mt-10">
      <div class="row">
        <div class="col-lg-7 d-flex align-items-center">
          <div v-if="leads" class="dashboard-card client-stats leads__box">
            <h2 class="title-text col-lg-12 p-0 mb-6">
              <span>Number of squads </span>
              <small class="sum-wrapper">
                {{ leads.total_projects }}
              </small>
            </h2>
            <h3 class="subtitle mb-1">
              <span>Number of clients </span>
              <small class="sum-wrapper">
                {{ leads.total_clients }}
              </small>
            </h3>
            <div
              v-if="
                leads &&
                !Number(leads.completed) &&
                !Number(leads.uncompleted) &&
                !Number(leads.active) &&
                !isLoadingLeads &&
                !loadingLeadsError
              "
              class="text-center text-xl"
            >
              <p class="mt-10 font-weight-bolder">
                We don't have available data for the current applied filters
              </p>
            </div>
            <div
              v-else-if="!isLoadingLeads && !loadingLeadsError"
              class="leads__wrapper mt-10"
            >
              <div class="availability_chart">
                <div
                  class="availability_chart__bar"
                  :style="`
                    --height: ${
                      (leads.completed /
                        (leads.completed + leads.uncompleted + leads.active)) *
                      100
                    }%;
                    --main-color: #ffc629;
                    --secondary-color: #ffd86e;`"
                ></div>
                <div
                  class="availability_chart__bar"
                  :style="`
                    --height: ${
                      (leads.uncompleted /
                        (leads.completed + leads.uncompleted + leads.active)) *
                      100
                    }%;
                    --main-color: #422e87;
                    --secondary-color: #4e3997;`"
                ></div>
                <div
                  class="availability_chart__bar"
                  :style="`
                    --height: ${
                      (leads.active /
                        (leads.completed + leads.uncompleted + leads.active)) *
                      100
                    }%;
                    --main-color: #3bd4ae;
                    --secondary-color: #6decd7;`"
                ></div>
              </div>
              <div class="availability">
                <ul>
                  <li>
                    <span
                      class="availability__value"
                      style="--bullet-color: #fac328"
                    >
                      {{ leads.completed }}
                    </span>
                    <span
                      class="availability__key"
                      v-b-tooltip.hover.top="
                        `Booked meetings on the level of the squad (project)`
                      "
                    >
                      Completed Lead
                    </span>
                  </li>
                  <li>
                    <span
                      class="availability__value"
                      style="--bullet-color: #422e87"
                    >
                      {{ leads.uncompleted }}
                    </span>
                    <span
                      class="availability__key"
                      v-b-tooltip.hover.top="
                        `Registered clients with no booked meetings (on the level of the project)`
                      "
                    >
                      Uncompleted Lead
                    </span>
                  </li>
                  <li>
                    <span
                      class="availability__value"
                      style="--bullet-color: #4eddbe"
                    >
                      {{ leads.active }}
                    </span>
                    <span class="availability__key">Active Squads</span>
                  </li>
                </ul>
              </div>
            </div>
            <div v-else-if="loadingLeadsError">
              <div class="text-center">
                <p class="font-weight-bolder text-danger">
                  Something went wrong, please try again
                </p>
                <v-btn
                  :loading="isLoadingLeads"
                  class="flex-grow-1 indigo-darken-3"
                  height="48"
                  variant="flat"
                  @click="getClientLeads"
                >
                  Try again
                </v-btn>
              </div>
            </div>
            <div
              v-else-if="isLoadingLeads && !loadingLeadsError"
              class="loading-state"
            >
              <v-progress-circular
                :size="50"
                :width="4"
                color="info"
                indeterminate
                class="mr-3"
              ></v-progress-circular>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="dashboard-card uncompleted-lead-chart-box">
            <h5 class="dashboard-title-text mb-4">
              <span>Nominations</span>
              <small class="sum-wrapper">
                {{ totalNominations }}
              </small>
            </h5>
            <div
              v-if="
                nominations &&
                !Object.keys(nominations) &&
                !isLoadingNominations &&
                !loadingNominationsError
              "
              class="text-center text-xl"
            >
              <p class="mt-10 font-weight-bolder">
                We don't have available data for the current applied filters
              </p>
            </div>

            <div
              v-else-if="!isLoadingNominations && !loadingNominationsError"
              class="nominations__wrapper d-flex"
            >
              <div class="nominations__wrapper--stats">
                <ul
                  v-if="nominations"
                  class="registration-talents registration-talents-lead"
                >
                  <li
                    v-for="(nominationKey, index) in Object.keys(nominations)"
                    :key="index"
                  >
                    <span
                      class="registration-talents__value"
                      :style="`--bullet-color: ${
                        chartsColors[index % chartsColors.length]
                      }`"
                    >
                      {{ nominations[nominationKey] }}
                    </span>
                    <span class="registration-talents__key">
                      {{ nominationKey | capitalize }}
                    </span>
                  </li>
                </ul>
              </div>
              <div
                v-if="nominations && Object.keys(nominations)"
                class="uncompleted-lead-chart-box__chart"
              >
                <DoughnutTotalNominations
                  class="foo-chart"
                  :width="240"
                  :height="240"
                  :data="nominations"
                  :labels="getNominationsKeys()"
                  :colors="chartsColors"
                  :loading="false"
                />
              </div>
            </div>
            <div v-else-if="loadingNominationsError">
              <div class="text-center">
                <p class="font-weight-bolder text-danger">
                  Something went wrong, please try again
                </p>
                <v-btn
                  :loading="isLoadingNominations"
                  class="flex-grow-1 indigo-darken-3"
                  height="48"
                  variant="flat"
                  @click="getClientNominations"
                >
                  Try again
                </v-btn>
              </div>
            </div>
            <div
              v-else-if="isLoadingNominations && !loadingNominationsError"
              class="loading-state"
            >
              <v-progress-circular
                :size="50"
                :width="4"
                color="info"
                indeterminate
                class="mr-3"
              ></v-progress-circular>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-10">
        <div class="col-md-6 col-lg-4">
          <div class="dashboard-card">
            <h3 class="title-text">Clients Campaign</h3>
            <div
              v-if="
                !clientCampaigns.length &&
                !isLoadingCampaigns &&
                !loadingCampaignsError
              "
              class="text-center text-xl"
            >
              <p class="mt-10 font-weight-bolder">
                We don't have available data for the current applied filters
              </p>
            </div>
            <template v-else-if="!isLoadingCampaigns && !loadingCampaignsError">
              <small>You have {{ clientCampaigns.length }} Campaigns</small>
              <ExpandedCard
                v-for="campaign in clientCampaigns"
                :key="campaign.id"
                class="my-5"
                :campaign-id="campaign.id"
                :campaign-name="campaign.name"
                :campaign-count="campaign.count"
                :date="{ start: startDate, end: endDate }"
                :thumbnail="campaign.thumbnail"
                :open="activeClientCampaignCard == campaign.id"
                @card-opened="activeClientCampaignCard = campaign.id"
              />
            </template>
            <div v-else-if="loadingCampaignsError">
              <div class="text-center mt-10">
                <p class="font-weight-bolder text-danger">
                  Something went wrong, please try again
                </p>
                <v-btn
                  :loading="isLoadingCampaigns"
                  class="flex-grow-1 indigo-darken-3"
                  height="48"
                  variant="flat"
                  @click="getClientCampaigns"
                >
                  Try again
                </v-btn>
              </div>
            </div>
            <div
              v-else-if="isLoadingCampaigns && !loadingCampaignsError"
              class="loading-state"
            >
              <v-progress-circular
                :size="50"
                :width="4"
                color="info"
                indeterminate
                class="mr-3"
              ></v-progress-circular>
            </div>
            <div class="max-height-overlay">
              <span @click="showClientsCampaignsModal = true"> View all </span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div
            class="dashboard-card"
            :class="{ accounts_wrapper: activeClients.length > 4 }"
          >
            <h3 class="title-text">Rec. Consultants & Deal Owners</h3>
            <div class="mt-5">
              <ul class="dashboard-switcher dashboard-switcher-accounts">
                <li>
                  <a
                    @click.prevent="activeTabForAccounts = 'consultants'"
                    href="#"
                    :class="
                      activeTabForAccounts == 'consultants' ? 'active' : ''
                    "
                    >Rec. Consultant</a
                  >
                </li>
                <li>
                  <a
                    @click.prevent="activeTabForAccounts = 'owners'"
                    href="#"
                    :class="activeTabForAccounts == 'owners' ? 'active' : ''"
                    >Deal Owner</a
                  >
                </li>
              </ul>
            </div>
            <div
              v-if="
                !activeClients.length &&
                !isLoadingAccounts &&
                !loadingAccountsError
              "
              class="text-center text-xl"
            >
              <p class="mt-10 font-weight-bolder">
                We don't have available data for the current applied filters
              </p>
            </div>
            <div
              v-else-if="!isLoadingAccounts && !loadingAccountsError"
              class="accounts_list"
            >
              <profileExpandedCard
                v-for="(client, index) in activeClients.slice(0, 4)"
                :key="index"
                class="my-5"
                :profile-image="client.thumbnail"
                :name="client.name"
                :email="client.email"
                :id="client.id"
                :date="{ start: startDate, end: endDate }"
                :no-of-squads="client.count"
                :type="activeTabForAccounts"
                :open="activeClientAccountCard == client.id"
                @card-opened="activeClientAccountCard = client.id"
              />
            </div>
            <div v-else-if="loadingAccountsError">
              <div class="text-center mt-10">
                <p class="font-weight-bolder text-danger">
                  Something went wrong, please try again
                </p>
                <v-btn
                  :loading="isLoadingAccounts"
                  class="flex-grow-1 indigo-darken-3"
                  height="48"
                  variant="flat"
                  @click="getClientAccounts"
                >
                  Try again
                </v-btn>
              </div>
            </div>
            <div
              v-else-if="isLoadingAccounts && !loadingAccountsError"
              class="loading-state"
            >
              <v-progress-circular
                :size="50"
                :width="4"
                color="info"
                indeterminate
                class="mr-3"
              ></v-progress-circular>
            </div>
            <div
              v-if="activeClients.length > 4"
              class="max-height-overlay"
              style="display: flex"
            >
              <span @click="showClientsAccountsModal = true"> View all </span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="dashboard-card">
            <div class="dashboard-title-text">
              Countries List
              <small
                v-if="countries.length && !isLoadingCountries"
                class="sum-wrapper"
              >
                {{ countries.length }}
              </small>
            </div>
            <div
              v-if="
                !countries.length &&
                !isLoadingCountries &&
                !loadingCountriesError
              "
              class="text-center text-xl"
            >
              <p class="mt-10 font-weight-bolder">
                We don't have available data for the current applied filters
              </p>
            </div>

            <template v-else-if="!isLoadingCountries && !loadingCountriesError">
              <div class="progress_bar-wrapper d-flex w-100 mb-8">
                <div
                  v-for="country in countries"
                  :key="country.id"
                  class="progress_bar"
                  :style="`--width: ${
                    (country.clients_count / countriesCount) * 100
                  }%; --color: ${country.color}`"
                  v-b-tooltip.hover.top="
                    `${country.name} ${country.clients_count}`
                  "
                ></div>
              </div>
              <div class="d-flex">
                <div class="row">
                  <div
                    class="col-lg-12"
                    v-for="country in countries"
                    :key="country.id"
                  >
                    <div class="countries-card">
                      <span
                        class="countries-card__color"
                        :style="`--color: ${country.color}`"
                      ></span>
                      <span class="countries-card__name">
                        {{ country.name }}
                      </span>
                      <span class="countries-card__number">
                        {{ country.clients_count }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div v-else-if="loadingCountriesError">
              <div class="text-center">
                <p class="font-weight-bolder text-danger">
                  Something went wrong, please try again
                </p>
                <v-btn
                  :loading="isLoadingCountries"
                  class="flex-grow-1 indigo-darken-3"
                  height="48"
                  variant="flat"
                  @click="getClientCountries"
                >
                  Try again
                </v-btn>
              </div>
            </div>
            <div
              v-else-if="isLoadingCountries && !loadingCountriesError"
              class="loading-state"
            >
              <v-progress-circular
                :size="50"
                :width="4"
                color="info"
                indeterminate
                class="mr-3"
              ></v-progress-circular>
            </div>
            <div class="max-height-overlay">
              <span @click="showCountriesModal = true"> View all </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showCountriesModal"
      persistent
      max-width="800px"
      transition="dialog-bottom-transition"
    >
      <v-card class="p-5 p-md-8">
        <ModalHeading modalTitle="Countries" />
        <div class="dialog-card-content">
          <div class="progress_bar-wrapper d-flex w-100 mb-8">
            <div
              v-for="country in countries"
              :key="country.id"
              class="progress_bar"
              :style="`--width: ${
                (country.clients_count / countriesCount) * 100
              }%; --color: ${country.color}`"
              v-b-tooltip.hover.top="country.name"
            ></div>
          </div>
          <div class="d-flex">
            <div class="row">
              <div
                class="col-lg-12"
                v-for="country in countries"
                :key="country.id"
              >
                <div class="countries-card">
                  <span
                    class="countries-card__color"
                    :style="`--color: ${country.color}`"
                  ></span>
                  <span class="countries-card__name">
                    {{ country.name }}
                  </span>
                  <span
                    v-if="activeTab == 'clients'"
                    class="countries-card__number"
                  >
                    {{ country.clients_count }}</span
                  >
                  <span v-else class="countries-card__number">
                    {{ country.talents_count }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-card-actions class="mt-10">
          <v-btn color="primary" @click="showCountriesModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showClientsAccountsModal"
      persistent
      max-width="800px"
      transition="dialog-bottom-transition"
    >
      <v-card class="p-5 p-md-8">
        <ModalHeading modalTitle="Accounts" />
        <div class="dialog-card-content">
          <profileExpandedCard
            v-for="(client, index) in activeClients"
            :key="index"
            class="my-5"
            modal
            :date="{ start: startDate, end: endDate }"
            :profile-image="client.thumbnail"
            :email="client.email"
            :name="client.name"
            :id="client.id"
            :no-of-squads="client.count"
            :type="activeTabForAccounts"
            :open="index == 0"
          />
        </div>
        <v-card-actions class="mt-10">
          <v-btn color="primary" @click="showClientsAccountsModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showClientsCampaignsModal"
      persistent
      max-width="800px"
      transition="dialog-bottom-transition"
    >
      <v-card class="p-5 p-md-8">
        <ModalHeading modalTitle="Clients Campaign" />
        <div class="dialog-card-content">
          <ExpandedCard
            v-for="(campaign, index) in clientCampaigns"
            :key="campaign.id"
            class="my-5"
            modal
            :date="{ start: startDate, end: endDate }"
            :campaign-id="campaign.id"
            :campaign-name="campaign.name"
            :campaign-count="campaign.count"
            :thumbnail="campaign.thumbnail"
            :open="index == 0"
          />
        </div>
        <v-card-actions class="mt-10">
          <v-btn color="primary" @click="showClientsCampaignsModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showTalentsCampaignsModal"
      persistent
      max-width="800px"
      transition="dialog-bottom-transition"
    >
      <v-card class="p-5 p-md-8">
        <ModalHeading modalTitle="Campaign talents" />
        <div class="dialog-card-content">
          <ExpandedCard
            v-for="(campaign, index) in talentsCampaigns"
            :key="campaign.id"
            class="my-5"
            :campaign-id="campaign.id"
            modal
            talents
            :date="{ start: startDate, end: endDate }"
            :campaign-name="campaign.name"
            :campaign-count="campaign.count"
            :thumbnail="campaign.thumbnail"
            :open="index == 0"
          />
        </div>
        <v-card-actions class="mt-10">
          <v-btn color="primary" @click="showTalentsCampaignsModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showProfileStrengthsModal"
      persistent
      max-width="800px"
      transition="dialog-bottom-transition"
    >
      <v-card class="p-5 p-md-8">
        <ModalHeading modalTitle="Profiles Strength" />
        <div class="dialog-card-content">
          <div class="d-flex justify-content-center mt-6">
            <DoughnutProfileStrength
              v-if="strengths.length > 1"
              :data="getStrengthsValues"
              :colors="colors"
              :labels="getStrengthsPercentages"
              :width="300"
              :height="300"
            />
          </div>
          <div class="d-flex">
            <ul class="profile-strength-list">
              <li v-for="strength in strengths" :key="strength.color">
                <span class="profile-strength-list__name">
                  <span
                    class="profile-strength-list__color"
                    :style="{ backgroundColor: strength.color }"
                  ></span>
                  <span class="profile-strength-list__text">
                    Strength ({{ strength.key }}%)</span
                  >
                </span>
                <span class="profile-strength-list__number">
                  {{ strength.value }}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <v-card-actions class="mt-10">
          <v-btn color="primary" @click="showProfileStrengthsModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end::Dashboard-->
    <!--
   <div class="text-center col-md-8 col-lg-6 px-0 mx-auto pt-5 mt-5">
      <img
        src="@/assets/images/welcome.svg"
        alt="welcome image"
        class="mw-100"
      />
      <h2 class="text-secondary my-5 py-5 h1">
        Welcome To Squadio Admin Panel
      </h2>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DoughnutRegistrationTalents from "@/components/UI/DoughnutRegistrationTalents.vue";
import DoughnutTotalNominations from "@/components/UI/DoughnutTotalNominations.vue";
import DoughnutProfileStrength from "@/components/UI/DoughnutProfileStrength.vue";
import ExpandedCard from "@/components/expnaded-card";
import ModalHeading from "@/components/UI/ModalHeading.vue";
import profileExpandedCard from "@/components/profile-expnaded-card";

export default {
  name: "dashboard",
  components: {
    DoughnutRegistrationTalents,
    DoughnutTotalNominations,
    DoughnutProfileStrength,
    ExpandedCard,
    ModalHeading,
    profileExpandedCard,
  },
  data() {
    return {
      activeTab: "clients",
      activeTabForAccounts: "consultants",
      countries: [],
      clientCampaigns: [],
      talentsCampaigns: [],
      activeClients: [],
      dates: [],
      strengths: [],
      startDate: null,
      endDate: null,
      datesApplied: false,
      dateRange: null,
      leads: null,
      nominations: null,
      talentsStats: null,
      menu: false,
      showCountriesModal: false,
      activeTalentCampaignCard: null,
      activeClientCampaignCard: null,
      activeClientAccountCard: null,
      showClientsAccountsModal: false,
      showProfileStrengthsModal: false,
      showClientsCampaignsModal: false,
      showTalentsCampaignsModal: false,
      isLoadingTalentsStats: false,
      isLoadingStrengths: false,
      loadingStrengthsError: false,
      loadingTalentsStatsError: false,
      isLoadingLeads: false,
      loadingLeadsError: false,
      isLoadingNominations: false,
      loadingNominationsError: false,
      isLoadingCampaigns: false,
      loadingCampaignsError: false,
      isLoadingAccounts: false,
      loadingAccountsError: false,
      countriesCount: 0,
      isLoadingCountries: false,
      loadingCountriesError: false,
      chartOptions: {},
      activeTabProfileStrength: "signups",
      profileStrengthData: [
        { percentage: 10, color: "#FF6C59" },
        { percentage: 20, color: "#422E87" },
        { percentage: 50, color: "#38D5AE" },
        { percentage: 80, color: "#28aaf5" },
        { percentage: 100, color: "#FEC629" },
      ],
      chartsColors: ["#ffc629", "#422e87", "#ff6f59", "#3bd4ae", "#28aaf5"],
      colors: [
        "#1abc9c",
        "#2039D3",
        "#9b59b6",
        "#FF6C59",
        "#34495e",
        "#3498db",
        "#e74c3c",
        "#2ecc71",
        "#f1c40f",
        "#e67e22",
      ],
    };
  },

  filters: {
    capitalize(str) {
      return str
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
  },

  computed: {
    canWeShowLeads() {
      if (!this.leads) return false;

      return (
        Number(this.leads.completed) ||
        Number(this.leads.uncompleted) ||
        Number(this.leads.active)
      );
    },
    canWeShowTalentStats() {
      if (!this.talentsStats) return false;

      return (
        this.talentsStats.hired &&
        Number(this.talentsStats.hired.full_time) &&
        Number(this.talentsStats.hired.part_time)
      );
    },
    totalNominations() {
      if (!this.nominations) return;
      let count = 0;
      Object.keys(this.nominations).forEach((key) => {
        count += Number(this.nominations[key]);
      });

      return count;
    },
    getStrengthsValues() {
      const values = [];
      this.strengths.forEach((strength) => {
        values.push(strength.value);
      });
      return values;
    },
    getStrengthsPercentages() {
      const percentages = [];
      this.strengths.forEach((strength) => {
        percentages.push(`Strength (${strength.key}%)`);
      });
      return percentages;
    },
  },

  watch: {
    async activeTabForAccounts() {
      await this.getClientAccounts();
    },
    async activeTabProfileStrength() {
      await this.getTalentsProfileStrengths();
    },
    async activeTab() {
      await this.getProperGetters();
    },
  },

  methods: {
    setStartAndEndDates() {
      this.$refs.startDataDialog.save(this.dates);
      if (this.dates.length < 2) return;

      const minDate =
        this.timeStamp(this.dates[0]) > this.timeStamp(this.dates[1])
          ? this.timeStamp(this.dates[1])
          : this.timeStamp(this.dates[0]);
      const maxDate =
        this.timeStamp(this.dates[0]) > this.timeStamp(this.dates[1])
          ? this.timeStamp(this.dates[0])
          : this.timeStamp(this.dates[1]);

      this.startDate = minDate;
      this.endDate = maxDate;

      this.datesApplied = true;

      this.getProperGetters();
    },
    timeStamp(date) {
      return Math.floor(new Date(date).getTime() / 1000);
    },
    resetDates() {
      this.datesApplied = false;
      this.dates = null;
      this.$refs.startDataDialog.save(this.dates);

      this.startDate = null;
      this.endDate = null;

      this.getProperGetters();
    },
    async getProperGetters() {
      if (this.activeTab == "clients") {
        await this.getClientLeads();
        await this.getClientNominations();
        await this.getClientCampaigns();
        await this.getClientCountries();
        await this.getClientAccounts();
      } else {
        await this.getTalentsStats();
        await this.getTalentsCountries();
        await this.getTalentsCampaigns();
        await this.getTalentsProfileStrengths();
      }

      const allDashboardCards = document.querySelectorAll(
        ".dashboard-card:not(.talent-stats):not(.client-stats)"
      );

      allDashboardCards.forEach((card) => {
        if (card.scrollHeight > 745) {
          card.classList.add("max-height");
          const maxHightElement = card.querySelector(".max-height-overlay");
          maxHightElement.style.display = "flex";
        }
      });
    },
    async getClientLeads() {
      this.isLoadingLeads = true;
      this.loadingLeadsError = false;
      let timeRange = "";

      if (this.startDate && !this.endDate)
        timeRange = `?start_date=${this.startDate}`;
      else if (this.startDate && this.endDate)
        timeRange = `?start_date=${this.startDate}&end_date=${this.endDate}`;

      await axios
        .get(`/home-stats/clients${timeRange}`)
        .then((res) => {
          this.leads = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.loadingLeadsError = true;
        })
        .finally(() => {
          this.isLoadingLeads = false;
        });
    },
    async getTalentsProfileStrengths() {
      this.isLoadingStrengths = true;
      this.loadingStrengthsError = false;
      let timeRange = "";

      if (this.startDate && !this.endDate)
        timeRange = `&start_date=${this.startDate}`;
      else if (this.startDate && this.endDate)
        timeRange = `&start_date=${this.startDate}&end_date=${this.endDate}`;

      await axios
        .get(
          `/home-stats/talents/profile?type=${this.activeTabProfileStrength}${timeRange}`
        )
        .then((res) => {
          this.strengths = res.data;
          this.strengths.forEach((strength, index) => {
            strength.color = this.colors[index % this.colors.length];
          });
        })
        .catch((err) => {
          console.error(err);
          this.loadingStrengthsError = true;
        })
        .finally(() => {
          this.isLoadingStrengths = false;
        });
    },
    async getTalentsStats() {
      this.isLoadingTalentsStats = true;
      this.loadingTalentsStatsError = false;
      let timeRange = "";

      if (this.startDate && !this.endDate)
        timeRange = `?start_date=${this.startDate}`;
      else if (this.startDate && this.endDate)
        timeRange = `?start_date=${this.startDate}&end_date=${this.endDate}`;

      await axios
        .get(`/home-stats/talents${timeRange}`)
        .then((res) => {
          this.talentsStats = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.loadingTalentsStatsError = true;
        })
        .finally(() => {
          this.isLoadingTalentsStats = false;
        });
    },
    async getClientNominations() {
      this.isLoadingNominations = true;
      this.loadingNominationsError = false;
      let timeRange = "";

      if (this.startDate && !this.endDate)
        timeRange = `?start_date=${this.startDate}`;
      else if (this.startDate && this.endDate)
        timeRange = `?start_date=${this.startDate}&end_date=${this.endDate}`;

      await axios
        .get(`/home-stats/clients/nominations${timeRange}`)
        .then((res) => {
          this.nominations = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.loadingNominationsError = true;
        })
        .finally(() => {
          this.isLoadingNominations = false;
        });
    },
    getNominationsKeys() {
      const nominationsKeys = [];
      Object.keys(this.nominations).forEach((key) => {
        nominationsKeys.push(key);
      });

      return nominationsKeys;
    },
    async getClientCampaigns() {
      this.isLoadingCampaigns = true;
      this.loadingCampaignsError = false;
      let timeRange = "";

      if (this.startDate && !this.endDate)
        timeRange = `&start_date=${this.startDate}`;
      else if (this.startDate && this.endDate)
        timeRange = `&start_date=${this.startDate}&end_date=${this.endDate}`;

      await axios
        .get(`/home-stats/campaigns?type=clients${timeRange}`)
        .then((res) => {
          this.clientCampaigns = res.data;
          this.clientCampaigns.forEach((campaign) => {
            const words = campaign.name.split(" ");
            let initials = "";

            for (const word of words) {
              initials += word[0];
            }

            campaign.thumbnail = `https://placehold.co/50x50/f3f2fe/422e87/?font=lato&text=${initials.toLocaleUpperCase()}`;
          });
        })
        .catch((err) => {
          console.error(err);
          this.loadingCampaignsError = true;
        })
        .finally(() => {
          this.isLoadingCampaigns = false;
        });
    },
    async getTalentsCampaigns() {
      this.isLoadingCampaigns = true;
      this.loadingCampaignsError = false;
      let timeRange = "";

      if (this.startDate && !this.endDate)
        timeRange = `&start_date=${this.startDate}`;
      else if (this.startDate && this.endDate)
        timeRange = `&start_date=${this.startDate}&end_date=${this.endDate}`;

      await axios
        .get(`/home-stats/campaigns?type=talents${timeRange}`)
        .then((res) => {
          this.talentsCampaigns = res.data;
          this.talentsCampaigns.forEach((campaign) => {
            const words = campaign.name.split(" ");
            let initials = "";

            for (const word of words) {
              initials += word[0];
            }

            campaign.thumbnail = `https://placehold.co/50x50/f3f2fe/422e87/?font=lato&text=${initials.toLocaleUpperCase()}`;
          });
        })
        .catch((err) => {
          console.error(err);
          this.loadingCampaignsError = true;
        })
        .finally(() => {
          this.isLoadingCampaigns = false;
        });
    },
    async getClientAccounts() {
      this.isLoadingAccounts = true;
      this.loadingAccountsError = false;
      let timeRange = "";

      if (this.startDate && !this.endDate)
        timeRange = `&start_date=${this.startDate}`;
      else if (this.startDate && this.endDate)
        timeRange = `&start_date=${this.startDate}&end_date=${this.endDate}`;

      await axios
        .get(
          `/home-stats/clients/accounts?type=${this.activeTabForAccounts}${timeRange}`
        )
        .then((res) => {
          this.activeClients = res.data;

          this.activeClients.forEach((client) => {
            const words = client.name.split(" ");
            let initials = "";

            for (const word of words) {
              initials += word[0];
            }

            client.thumbnail = `https://placehold.co/50x50/eee/bbb/?font=lato&fontWeight=100&text=${initials.toLocaleUpperCase()}`;
          });
        })
        .catch((err) => {
          console.error(err);
          this.loadingAccountsError = true;
        })
        .finally(() => {
          this.isLoadingAccounts = false;
        });
    },
    async getClientCountries() {
      this.isLoadingCountries = true;
      this.countriesCount = 0;
      this.loadingCountriesError = false;
      let timeRange = "";

      if (this.startDate && !this.endDate)
        timeRange = `&start_date=${this.startDate}`;
      else if (this.startDate && this.endDate)
        timeRange = `&start_date=${this.startDate}&end_date=${this.endDate}`;

      await axios
        .get(`/home-stats/countries?type=clients${timeRange}`)
        .then((res) => {
          this.countries = res.data.data.filter(
            (country) => country.clients_count !== 0
          );
          if (res.data.with_no_country) {
            this.countries.push({
              name: "No country selected",
              clients_count: res.data.with_no_country,
            });
          }
          this.countries.sort((a, b) => b.clients_count - a.clients_count);

          this.countries.forEach((country, index) => {
            this.countriesCount += country.clients_count;
            country.color = this.colors[index % this.colors.length];
          });
        })
        .catch((err) => {
          console.error(err);
          this.loadingCountriesError = true;
        })
        .finally(() => {
          this.isLoadingCountries = false;
        });
    },
    async getTalentsCountries() {
      this.isLoadingCountries = true;
      this.countriesCount = 0;
      this.loadingCountriesError = false;
      let timeRange = "";

      if (this.startDate && !this.endDate)
        timeRange = `&start_date=${this.startDate}`;
      else if (this.startDate && this.endDate)
        timeRange = `&start_date=${this.startDate}&end_date=${this.endDate}`;

      await axios
        .get(`/home-stats/countries?type=talents${timeRange}`)
        .then((res) => {
          this.countries = res.data.data.filter(
            (country) => country.talents_count !== 0
          );
          if (res.data.with_no_country) {
            this.countries.push({
              name: "No country selected",
              talents_count: res.data.with_no_country,
            });
          }
          this.countries.sort((a, b) => b.talents_count - a.talents_count);

          this.countries.forEach((country, index) => {
            this.countriesCount += country.talents_count;
            country.color = this.colors[index % this.colors.length];
          });
        })
        .catch((err) => {
          console.error(err);
          this.loadingCountriesError = true;
        })
        .finally(() => {
          this.isLoadingCountries = false;
        });
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Admin Panel" },
      { title: "Dashboard", hideLastLabel: true },
    ]);

    this.chartOptions = {
      series: [44, 55],
      labels: [],
    };

    await this.getProperGetters();
  },
};
</script>

<style lang="scss">
.dashboard-switcher {
  background-color: rgba(241, 241, 241, 1);
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  padding: 10px;
  width: max-content;
  flex-wrap: nowrap;
  // max-height: 68px;

  li {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      width: 100%;
    }
  }
}

.dashboard-switcher-profile-strength {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #c9c9c9;
  background-color: #fff;
}

.dashboard-switcher-profile-strength li {
  width: 50%;
  margin-right: 0 !important;
}

.dashboard-switcher li {
  margin-right: 0.25rem;
}

.dashboard-switcher li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.8rem 4.5rem;
  border-radius: 8px;
  font-size: 18px;
  color: rgba(73, 77, 85, 1);
  font-weight: 600;
}

.dashboard-switcher-profile-strength li a {
  padding: 0.8rem 0rem !important;
  text-align: center;
  font-size: 14px;
  justify-content: center;
}

.dashboard-switcher li a.active {
  color: #fff;
  background-color: #422e87;
}

.dashboard-switcher-accounts {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #c9c9c9;
  background-color: #fff;
}

.dashboard-switcher-accounts li {
  width: 50%;
  margin-right: 0 !important;
}

.dashboard-switcher-accounts li a {
  padding: 0.8rem 1rem !important;
  text-align: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
}

.dashboard-switcher-accounts li a.active {
  color: #422e87;
  border: 1px solid #c9c9c9;
  background-color: #fff;
}

.dashboard-switcher-profile-strength li a.active {
  color: #422e87;
  border: 1px solid #c9c9c9;
  background-color: #fff;
}

.dashboard-title-text {
  font-size: 18px;
  color: rgba(32, 32, 32, 1);
  font-weight: 600;
  margin-bottom: 32px !important;
}

.dashboard-card {
  position: relative;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 19px;
  padding: 28.8px;

  @media (max-width: 1400px) {
    margin: 10px 0;
  }

  &.max-height {
    position: relative;
    max-height: 750px;
    padding-bottom: 240px;
    overflow: hidden;
  }

  .max-height-overlay {
    position: absolute;
    bottom: 0;
    width: calc(100% - 40px);
    height: 120px;
    display: none;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 15px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );

    span {
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.row {
  width: 100%;
  margin: 0 auto;
}

.take-assessment-summary {
  display: flex;
  width: 100%;
  justify-content: space-between;

  li {
    list-style: none;
    background-color: #efecfa;
    padding: 15px 20px;
    border-radius: 8px;
    width: 48%;
    background-color: #9a85e029;
    border: 1.2px solid #f1f1f1;
    border-radius: 19.2px;

    .take-assessment-summary__value {
      display: block;
      font-size: 28.8px;
      color: #000000;
      font-weight: 700;
    }

    .take-assessment-summary__key {
      display: block;
      font-weight: 300;
      font-size: 19.2px;
      color: #808285;
    }
  }
}

.assessment-roles-covered {
  justify-content: space-between;
  align-items: baseline;
  margin-top: 1rem;

  p {
    font-size: 19.2px;
    color: #414042;
  }

  .v-progress-circular__info {
    color: #000;
    font-weight: 900;
  }
}

.registration-talents-lead {
  margin-top: 1rem !important;
}

.registration-talents {
  margin-top: 3rem;

  li:first-of-type {
    margin-bottom: 1rem;
  }

  li {
    list-style: none;

    .registration-talents__value {
      display: flex;
      font-weight: 800;
      font-size: 20px;
      margin-top: 10px;
      margin-right: 10px;
      color: #000000;

      &::before {
        height: 12px;
        width: 12px;
        background-color: var(--bullet-color);
        border-radius: 50%;
        display: inline-block;
        content: "";
        margin-right: 10px;
        margin-top: 7px;
      }
    }

    .registration-talents__key {
      display: block;
      color: #8f8d9a;
      font-weight: 300;
      font-size: 14px;
      color: #666666;
      white-space: nowrap;

      &::before {
        height: 12px;
        width: 12px;
        background-color: var(--bullet-color);
        border-radius: 50%;
        display: inline-block;
        content: "";
        margin-right: 10px;
        margin-top: 7px;
      }
    }
  }
}

.main-talents-statistics-wrapper {
  border-right: 1px solid #d7d6d6;
  padding-right: 15px;

  @media (max-width: 1310px) {
    border-right-color: transparent;
    border-bottom: 1px solid #d7d6d6;
    padding-bottom: 15px;
  }
}

.countries-card {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0;
  align-items: center;
}

.countries-card__number {
  display: flex;
  margin-left: auto;
  font-size: 14px;
  font-weight: 700;
}

.countries-card__color {
  height: 3rem;
  width: 7px;
  display: flex;
  background: var(--color);
  border-radius: 8px;
  margin-right: 0.5rem;
}

.availability {
  margin-inline-start: 20px;

  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  li {
    list-style: none;
  }

  .availability__value {
    display: flex;
    font-size: 20px;
    font-weight: 700;
    align-items: center;

    &::before {
      height: 12px;
      width: 12px;
      background-color: var(--bullet-color);
      border-radius: 50%;
      display: inline-block;
      content: "";
      margin-right: 10px;
    }
  }

  .availability__key {
    color: #422e87;
    font-weight: 300;
    font-size: 18px;
    margin-left: 2rem;
  }
}

.availability_chart {
  height: 129px;
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;

  .availability_chart__bar {
    width: 25px;
    border-radius: 14px;
    margin-right: 15px;
    height: var(--height);
    background-color: var(--main-color);
    background-image: -webkit-linear-gradient(
      -164deg,
      var(--main-color) 0%,
      var(--secondary-color) 100%
    );
    background-image: -moz-linear-gradient(
      -164deg,
      var(--main-color) 0%,
      var(--secondary-color) 100%
    );
    background-image: -o-linear-gradient(
      -164deg,
      var(--main-color) 0%,
      var(--secondary-color) 100%
    );
    background-image: linear-gradient(
      -164deg,
      var(--main-color) 0%,
      var(--secondary-color) 100%
    );
  }
}

.leads__wrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .availability {
    @media (max-width: 992px) {
      align-self: flex-start;
      margin: 0;
    }
  }

  .availability ul {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 0;

    @media (max-width: 992px) {
      margin-top: 30px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    @media (min-width: 992px) and (max-width: 1310px) {
      flex-wrap: wrap;
    }
  }

  .availability ul li {
    margin: 0 10px;
  }

  .availability__key {
    color: #a5a5a5;
    font-size: 14px;
  }

  .availability__value {
    position: relative;
    font-size: 18px;
  }
}

.nominations__wrapper {
  display: flex;

  @media (max-width: 992px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 20px;
  }

  &--stats,
  uncompleted-lead-chart-box__chart {
    width: 50%;

    @media (max-width: 992px) {
      width: 100%;
    }
  }

  ul {
    width: 100%;
  }
}

.signup_chart_wrapper {
  @media (max-width: 1399px) {
    margin-top: 40px;
  }
}

.availability_chart_wrapper {
  padding-left: 40px;

  @media (max-width: 992px) {
    padding: 10px;
    margin-top: 40px;
  }

  @media (max-width: 1399px) {
    margin-top: 40px;
  }
}

.profile-strength-list {
  display: flex;
  width: 100%;
  flex-direction: column;

  li {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .profile-strength-list__name {
    display: flex;
  }

  .profile-strength-list__text {
    font-size: 14px;
    font-weight: 600;
  }

  .profile-strength-list__color {
    height: 12px;
    width: 12px;
    border-radius: 4px;
    background-color: red;
    display: flex;
    margin-top: 3px;
    margin-right: 1rem;
  }

  .profile-strength-list__number {
    font-size: 15px;
    font-weight: 700;
  }
}

.leads__box {
  padding-bottom: 80px;
}

.leads__box h2 {
  margin-bottom: 50px;
}

.lead-summary {
  margin-bottom: 0;

  li {
    list-style: none;
    display: flex;
    align-items: center;
  }

  .lead-summary__icon {
    height: 5rem;
    width: 5rem;
    background-color: #fffae4;
    border-radius: 50%;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .lead-summary__data {
    display: flex;
    flex-direction: column;
  }

  .lead-summary__number {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 1rem;
  }

  .lead-summary__text {
    font-size: 14px;
    font-weight: 400;
  }
}

.title-text {
  font-size: 18px;
  color: rgb(32, 32, 32);
  font-weight: 600;
}

.sum-wrapper {
  background-color: #f1edff;
  padding: 7px 20px;
  margin-left: 10px;
  border-radius: 19px;
  font-weight: bold;
}

.dashboard-filter {
  display: flex;
  align-items: center;

  select {
    width: 180px;
    margin-right: 22px;
  }

  .v-icon.v-icon {
    color: #422e87;
  }

  .btn.btn-secondary {
    color: #202020;
    background-color: #fff;
    border-color: #fff;
    border-radius: 14px;
    box-shadow: 0 4px 4px 0 #0000000a;
  }

  .btn.btn-secondary:hover:not(.btn-text):not(:disabled):not(.disabled),
  .btn.btn-secondary:focus:not(.btn-text),
  .btn.btn-secondary.focus:not(.btn-text) {
    color: #202020;
    background-color: #fff;
    border-color: #fff;
  }

  .v-input__slot {
    margin: 0;
    color: #202020;
    background-color: #fff;
    border-color: #fff;
    padding: 0.825rem 1.42rem;
    font-size: 1.08rem;
    line-height: 1.5;
    border-radius: 14px;
    box-shadow: 0 4px 4px 0 #0000000a !important;
    flex-wrap: wrap;
  }

  .v-text-field__slot {
    order: 2;
  }

  .v-input__append-inner {
    order: 3;
  }

  .v-input__append-inner:last-child {
    order: 1;
  }

  .v-text-field.v-text-field--solo .v-input__control {
    min-height: 60px;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
  }

  .v-input {
    margin-right: 22px;
  }
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: 0;
}

.loading-state {
  width: 100%;
  margin: 40px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress_bar-wrapper {
  border-radius: 5px;
  overflow: hidden;
}

.progress_bar {
  width: var(--width);
  height: 20px;
  background-color: var(--color);
}

.error-state {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-card-content {
  height: calc(90vh - 205px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
}

.dialog-card-content::-webkit-scrollbar {
  width: 4px;
}

.dialog-card-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.dashboard-cta-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .dashboard-filter {
    align-self: flex-end;
  }
}

@media (min-width: 1264px) {
  .container {
    max-width: 1264px;
  }
}

.accounts_wrapper .accounts_list {
  padding-bottom: 50px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.subtitle {
  font-size: 16px;
}

@media (min-width: 992px) {
  .aside-fixed .wrapper {
    padding-left: 70px;
  }

  .aside-enabled.subheader-fixed .subheader {
    left: 70px;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    > .menu-item
    > .menu-link
    .menu-text {
    display: flex;
  }

  .aside-minimize:not(.aside-minimize-hover) .aside {
    width: 70px;
  }

  .aside-menu .menu-nav > .menu-item > .menu-link {
    padding: 9px 30px;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    > .menu-item
    > .menu-link
    > .menu-icon {
    margin-right: 10px;
  }
}

.talents-stats__wrapper {
  display: flex;
  flex-direction: column;

  @media (min-width: 1310px) {
    flex-direction: row;

    .availability_chart_wrapper {
      width: 40%;
    }
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 1440px;
  }
}
</style>
