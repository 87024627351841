<template>
  <div class="registration-talents-donut">
    <apexchart
      :width="width"
      :height="height"
      :options="options"
      :series="data"
      type="donut"
    >
    </apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Chart",
  props: {
    title: {
      type: String,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
    data: {
      type: Array,
    },
    labels: {
      type: Array,
    },
    colors: {
      type: Array,
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    options() {
      return {
        dataLabels: {
          enabled: false,
        },
        chart: {
          type: "donut",
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: "Total",
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#999999",
                },
              },
            },
          },
        },
        colors: this.colors,
        noData: {
          text: this.loading ? "Loading..." : "No Data",
        },
        labels: this.labels,
      };
    },
  },
};
</script>

<style>
.registration-talents-donut .apexcharts-legend {
  display: none !important;
}

.registration-talents-donut .apexcharts-datalabels-group {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.registration-talents-donut .apexcharts-datalabel-value {
  font-size: 38.4px;
  color: #333333;
  font-weight: 700;
}
</style>
